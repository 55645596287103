var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white"},[_c('el-breadcrumb',{staticClass:"p-5 border-b border-solid",attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{
        path:
          _vm.userInfo.role === 'admin'
            ? '/pages/select/role'
            : !_vm.userInfo.no
            ? '/pages/home/home'
            : '/pages/select/score',
      }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("修改密码")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"w-full h-full flex items-center justify-center"},[_c('el-form',{ref:"editForm",staticClass:"w-1/3",attrs:{"model":_vm.editForm,"rules":_vm.rule,"label-width":"80px"}},[_c('h3',{staticClass:"text-center text-2xl p-10"},[_vm._v("修改密码")]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"旧密码","prop":"password"}},[_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.editForm.password),callback:function ($$v) {_vm.$set(_vm.editForm, "password", $$v)},expression:"editForm.password"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"新密码","prop":"newPassword"}},[_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.editForm.newPassword),callback:function ($$v) {_vm.$set(_vm.editForm, "newPassword", $$v)},expression:"editForm.newPassword"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"确认密码","prop":"rNewPassword"}},[_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.editForm.rNewPassword),callback:function ($$v) {_vm.$set(_vm.editForm, "rNewPassword", $$v)},expression:"editForm.rNewPassword"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',[_c('el-button',{staticStyle:{"width":"calc(50% - 5px)"},attrs:{"size":"mini"},on:{"click":_vm.handleBack}},[_vm._v("返 回")]),_c('el-button',{staticStyle:{"width":"calc(50% - 5px)","margin-left":"10px"},attrs:{"size":"mini","type":"primary"},on:{"click":_vm.handleUpdatePassword}},[_vm._v("确认修改")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }