<!--
 * ======================================
 * 说明： 用户设置
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/5/11 10:34
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="bg-white">
    <el-breadcrumb
      class="p-5 border-b border-solid"
      separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item
        :to="{
          path:
            userInfo.role === 'admin'
              ? '/pages/select/role'
              : !userInfo.no
              ? '/pages/home/home'
              : '/pages/select/score',
        }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-loading="loading" class="w-full h-full flex items-center justify-center">
      <el-form
        :model="editForm"
        ref="editForm"
        :rules="rule"
        label-width="80px"
        class="w-1/3"
      >
        <h3 class="text-center text-2xl p-10">修改密码</h3>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="旧密码" prop="password">
              <el-input
                show-password
                v-model="editForm.password"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="新密码" prop="newPassword">
              <el-input
                show-password
                v-model="editForm.newPassword"
              ></el-input></el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="确认密码" prop="rNewPassword">
              <el-input
                show-password
                v-model="editForm.rNewPassword"
              ></el-input></el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-button
                size="mini"
                style="width: calc(50% - 5px)"
                @click="handleBack"
                >返 回</el-button
              >
              <el-button
                size="mini"
                style="width: calc(50% - 5px); margin-left: 10px"
                type="primary"
                @click="handleUpdatePassword"
                >确认修改</el-button
              >
            </el-form-item></el-col
          >
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import { clearLocalStorage } from "@/util/storage.js";
import Cookies from "js-cookie";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      loading:false,
      editForm: {},
      userInfo: {},
      rule: {
        password: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入密码"));
              } else if (value.length < 6) {
                callback(new Error("密码长度不能小于6位"));
              } else {
                if (this.editForm.rNewPassword !== "") {
                  this.$refs.editForm.validateField("rNewPassword");
                }
                callback();
              }
            },
          },
        ],
        rNewPassword: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.editForm.newPassword) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  async mounted() {
    await this.getLoginInfo();
  },
  methods: {
    /**
     *  @actions:  获取登录信息
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/17 15:38
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getLoginInfo() {
      await this.$cloud.get("/login/info").then((res) => {
        this.userInfo = res;
      }).catch(err=>{
        this.$message.error(err)
      });
    },

    /**
     *  @actions:  返回
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/17 14:46
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleBack() {
      this.$router.go(-1);
    },

    /**
     *  @actions:  更新密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/17 16:14
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleUpdatePassword() {
      this.$refs["editForm"].validate((vaild) => {
        if (vaild) {
          this.$confirm("确定要修改密码吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            const { password, newPassword } = this.editForm;
            this.loading = true;
            this.$cloud
              .post("/login/password", {
                password,
                newPassword,
              })
              .then(() => {
                this.loading = false;
                this.editForm = {};
                this.$message.success("修改成功，请重新登录系统！");
  
                setTimeout(() => {
                  Cookies.remove("hankun_cloud_template_token");
                  clearLocalStorage();
                  this.$cloud.go("/pages/login/login");
                }, 1000);
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
